<template>
  <div class="about">
    <img
      class="about__img--top"
      src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/poster2.jpg"
    />
    <Declare />
    <div class="about__detail">
      <div class="about__brief" :style="styles.brief.brief">
        <h3 class="about__brief--h3" :style="styles.brief.h3">企业简介</h3>
        <div class="about__brief--warp">
          <p class="about__brief--warptext" :style="styles.brief.warptext">
            水羊直供是A股上市公司（300740）旗下品牌直供平台，主要经营国货知
            <br />名面膜品牌御泥坊、芬兰国宝护肤品牌LUMENE、日本知名药妆品牌城野
            <br />医生、米兰时装周御用彩妆品牌KIKO等40+国内外知名彩妆护肤品牌。
            <br />平台所供品牌皆为公司自营或国内独家代理品牌，保证正品和产品品质；
            <br />坚持品牌直接供货，无其他中间环节，保证供货折扣；共享公司十余
            <br />年电商运营经验和完善的供应链系统，保证合作无后顾之忧。
            <br />未来，平台将引入更多全球优质品牌，满足店主多元化需求。
            <br />水羊直供，成就每一个客户。
          </p>
          <img
            :style="styles.brief.warpimg"
            src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/logoLevel/logo_name_460_210.png"
          />
        </div>
      </div>
      <div class="about__brand" :style="styles.brand.brand">
        <h3 class="about__brand--h3" :style="styles.brand.h3">国货品牌</h3>
        <div class="about__brand--warp">
          <div :key="item.name" class="about__brand--item" v-for="item in autarky">
            <div class="about__brand--items" :style="styles.brand.items">
              <img :src="item.img" class="about__brand--icon" :style="styles.brand.icon" />
              <p class="about__brand--p about__brand--name" :style="styles.brand.p">
                {{ item.name }}
              </p>
              <p class="about__brand--p about__brand--title" :style="styles.brand.p">
                {{ item.title }}
              </p>
              <p
                class="about__brand--p about__brand--introduce"
                :style="[styles.brand.p, styles.brand.introduce]"
                v-html="item.introduce"
              ></p>
            </div>
          </div>
          <div class="about__brand--bar" :style="styles.brand.bar"></div>
          <div class="about__brand--bar" :style="styles.brand.bar"></div>
        </div>
      </div>
      <div class="about__brand about__internationalBrand" :style="styles.brand.brand">
        <h3 class="about__brand--h3" :style="styles.brand.h3">国际品牌</h3>
        <div class="about__brand--warp">
          <div :key="item.name" class="about__brand--item" v-for="item in internationalBrand">
            <div class="about__brand--items" :style="styles.brand.items2">
              <img :src="item.img" class="about__brand--icon" />
              <p class="about__brand--p about__brand--name" :style="styles.brand.p">
                {{ item.name }}
              </p>
              <p class="about__brand--p about__brand--title" :style="styles.brand.p">
                {{ item.title }}
              </p>
              <p
                class="about__brand--p about__brand--time"
                :style="[styles.brand.p, styles.brand.time]"
              >
                {{ item.time }}
              </p>
              <p
                class="about__brand--p about__brand--introduce"
                :style="[styles.brand.p, styles.brand.introduce]"
                v-html="item.introduce"
              ></p>
            </div>
          </div>
          <div class="about__brand--bar"></div>
        </div>
      </div>
      <div class="about__code" :style="styles.code.code">
        <img
          alt="about"
          class="about__code--img"
          :style="styles.code.imgcode"
          src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/logoLevel/wx-code.png"
        />
        <img
          alt="about"
          class="about__code--img"
          :style="styles.code.imglogo"
          src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/logoLevel/logoName_190_60.png"
        />
        <p class="about__code--text" :style="styles.code.text">成就每一个客户</p>
      </div>
    </div>
  </div>
</template>
<script>
import Declare from '@/components/Declare.vue';
const clientWidth = document.body.clientWidth;
export default {
  name: 'About',
  components: {
    Declare,
  },
  data() {
    return {
      autarky: [
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/brand/1.png',
          name: '御泥坊',
          title: '连续数年获得天猫金妆奖',
          introduce: 'Won the Tmall gold <br />makeup award for several <br />consecutive years',
        },
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/brand/2.png',
          name: '御泥坊男士',
          title: '专业男士护理品牌',
          introduce: "Professional men's<br />care brand",
        },
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/brand/3.png',
          name: '小迷糊',
          title: '跨界卡通形象合作青春面膜品牌',
          introduce: 'Cartoon image cooperation<br />Youth mask brand',
        },
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/brand/4.png',
          name: '大水滴',
          title: '收购台湾面膜品牌',
          introduce: 'Acquired Taiwanese<br />facial mask brand',
        },
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/brand/5.png',
          name: '花瑶花',
          title: '德国红点设计大奖',
          introduce: 'German Red Dot<br />Design Award',
        },
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/brand/6.png',
          name: 'HPH',
          title: '日本合作医美品牌',
          introduce: 'Medical beauty<br />brand',
        },
      ],
      internationalBrand: [
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/internationalBrand/1.png',
          name: 'EviDenS',
          title: '法国高奢抗衰护肤品牌',
          time: '- 2019年合作 -',
          introduce: 'Cooperation from 2018 French<br />luxury anti-aging skin care brand',
        },
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/internationalBrand/2.png',
          name: 'KIKO',
          title: '意大利时尚彩妆品牌',
          time: '- 2018年合作 -',
          introduce: 'Cooperation from 2018 Italian<br />fashion color brand',
        },
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/internationalBrand/3.png',
          name: '大宝',
          title: '国民护肤品牌',
          time: '- 2019年合作 -',
          introduce: 'Cooperation from 2019 National<br />Skincare Brand',
        },
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/internationalBrand/4.png',
          name: 'LUMENE',
          title: '芬兰国民护肤品牌',
          time: '- 2018年合作 -',
          introduce: ' Cooperation from 2018 Finnish<br />national skin care brand',
        },
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/internationalBrand/5.png',
          name: 'ZELENS',
          title: '英国高端抗衰品牌',
          time: '- 2018年合作 -',
          introduce: 'Cooperation from 2018 British<br />high-end anti-aging brand',
        },
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/internationalBrand/6.png',
          name: 'LIERAC',
          title: '法国尖端实验室医美护肤品牌',
          time: '- 2019年合作 -',
          introduce: 'ooperation from 2019 French<br />cutting-edge dermocosmetics brand',
        },
        {
          img: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/internationalBrand/7.png',
          name: '李施德林',
          title: '美国专业口腔护理品牌',
          time: '- 2019年合作 -',
          introduce: 'Cooperation from 2019 American<br />professional oral care brand',
        },
      ],
      styles: {
        brief: {
          brief: {
            color: 'red',
            padding: `${this.fontSizePx(100)} 15%`,
          },
          h3: {
            fontSize: this.fontSizePx(24),
            lineHeight: this.fontSizePx(36),
            marginBottom: this.fontSizePx(20),
          },
          warptext: {
            fontSize: this.fontSizePx(16),
            lineHeight: this.fontSizePx(30),
          },
          warpimg: {
            width: this.fontSizePx(460),
            height: this.fontSizePx(210),
          },
        },
        brand: {
          brand: {
            padding: `0 15% ${this.fontSizePx(112)}`,
          },
          h3: {
            fontSize: this.fontSizePx(40),
            marginBottom: this.fontSizePx(90),
          },
          items: {
            width: this.fontSizePx(277),
            height: this.fontSizePx(277),
          },
          items2: {
            width: this.fontSizePx(277),
            height: this.fontSizePx(277),
          },
          icon: {
            width: this.fontSizePx(120),
            height: this.fontSizePx(120),
          },
          p: {
            fontSize: this.fontSizePx(18),
          },
          time: {
            fontSize: this.fontSizePx(16),
          },
          introduce: {
            fontSize: this.fontSizePx(14),
            lineHeight: this.fontSizePx(18),
          },
          bar: {
            width: this.fontSizePx(277),
          },
        },
        code: {
          code: {
            padding: `${this.fontSizePx(162)} 0 ${this.fontSizePx(70)}`,
          },
          imgcode: {
            width: this.fontSizePx(186),
            height: this.fontSizePx(186),
            marginBottom: this.fontSizePx(44),
          },
          imglogo: {
            width: this.fontSizePx(180),
            height: this.fontSizePx(50),
            marginBottom: this.fontSizePx(12),
          },
          text: {
            fontSize: this.fontSizePx(18),
          },
        },
      },
    };
  },
  methods: {
    fontSizePx(val) {
      return `${(clientWidth * val) / 1920}px`;
    },
  },
};
</script>
<style lang="scss" scoped>
.about {
  &__img {
    &--top {
      width: 100%;
      display: block;
    }
  }
  &__brief {
    background: url('https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/brief-bg.jpg');
    background-size: cover;
    &--h3 {
      width: 100%;
      color: #d80b30;
      position: relative;
      &::before {
        content: '';
        width: 25px;
        height: 2px;
        background: #d80a30;
        position: absolute;
        left: 0;
        bottom: -10px;
      }
    }
    &--warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &--warptext {
      color: #3a3a3a;
    }
  }
  &__brand {
    background: url('https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/brand-bg-1.jpg');
    background-size: cover;
    overflow: hidden;
    &--h3 {
      color: #d80b30;
      text-align: center;
    }
    &--warp {
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
    }
    &--item {
      flex: 0 0 25%;
      text-align: center;
    }
    &--items {
      margin: 0 auto;
      transition: all 0.1s;
      &:hover {
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 27px 0px rgba(169, 169, 169, 0.25);
        border-radius: 50%;
      }
    }
    &--icon {
      display: block;
      margin: 0 auto;
    }
    &--p {
      color: #3a3a3a;
    }
    &--name {
      margin-bottom: 10px;
    }
    &--title {
      margin-bottom: 18px;
    }
    &--time {
      color: #d80b30;
    }
    &--introduce {
      color: #6e6e6e;
    }
    &--bar {
      flex: 0 0 25%;
    }
  }
  &__internationalBrand {
    background: url('https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/brand-bg-2.jpg')
      repeat-y;
  }
  &__code {
    text-align: center;
    background: url('https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/about/map-bg.jpg') center
      no-repeat;
    &--img {
      display: block;
      margin: 0 auto;
    }
    &--text {
      color: #6d6d6d;
      letter-spacing: 4px;
    }
  }
}
</style>
